<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.order_no" placeholder="请输入订单"/>
            </a-form-item>
          </a-col>
          <!--          <a-col :md="4" :sm="24">
            <a-form-item label="商品名称">
              <a-input v-model="queryParam.commodity_name" placeholder="请输入商品名称"/>
            </a-form-item>
          </a-col>-->
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.ExhibitionCommodityStatus">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 170px">
        {{ (text / 100).toFixed(2) }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDetail(record)">详情</a>
          <a-divider type="vertical" v-if="record.status === 1"/>
        </template>
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'

import {
  exhibition_order_record_refund_list
} from '@/api/exhibition'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      exhibition_warehouse_value: '',
      exhibition_goods_value: '',
      bonded_commodity_value: '',
      dataSource_exhibition_warehouse: [],
      dataSource_exhibition_goods: [],
      dataSource_bonded_commodity: [],
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '订单号',
          dataIndex: 'order_no'
        },
        {
          title: '金额',
          dataIndex: 'amount',
          align: 'center',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '退回方式',
          dataIndex: 'refund_mode',
          customRender: (text) => this.$Dictionaries.ExhibitionRefundMode[text]
        },
        /* {
          title: '支付方式',
          dataIndex: 'pay_tool',
          customRender: (text) => this.$Dictionaries.PayTool[text]
        }, */
        {
          title: '创建时间',
          customRender: (text) => text || '-',
          dataIndex: 'create_time'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return exhibition_order_record_refund_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    console.log(this.$Dictionaries)
  },
  methods: {
    handleDetail (record) {
      this.$router.push({ path: '/exhibition/order/detail', query: { id: record.id } })
    }
  }
}
</script>
